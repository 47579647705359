<template>
  <v-popover :popover-base-class="'tooltip popover tooltip-white tooltip-wide p-0'">
    <button
      class="text-white focus:outline-none hover:bg-gray-800 px-4 py-3 rounded-full"
      @click.prevent
    >
      <fa-icon icon="bell" />
    </button>

    <template slot="popover">
      <div class="p-2 text-black">
        <ul class="flex">
          <li class="flex-1 text-center">
            <a
              :class="{ 'border-gray-600 text-blue-500 font-bold' : active === 'notifications' }"
              class="bg-white block py-3 px-4 text-gray-500 hover:text-blue-500 border-b-2 hover:border-gray-600 font-semibold flex justify-center"
              href="#"
              @click.prevent="active = 'notifications'"
            >Notifications <span
              class="rounded-full h-5 w-5 flex items-center justify-center bg-red-500 text-white text-xs ml-3"
            >2</span></a>
          </li>
          <li class="flex-1 text-center">
            <a
              :class="{ 'border-gray-600 text-blue-500 font-bold' : active === 'activity' }"
              class="bg-white block py-3 px-4 text-gray-500 border-b-2 hover:text-blue-500  hover:border-gray-600 font-semibold "
              href="#"
              @click.prevent="active = 'activity'"
            >Activity</a>
          </li>
        </ul>

        <div
          style="height:80vh"
          class="overflow-y-auto"
        >
          <ActivityList v-if="active === 'activity'" />
          <NotificationsList v-else />
        </div>
      </div>
    </template>
  </v-popover>
</template>
<script>
const ActivityList = () => import('@components/activity/ActivityContainer')
const NotificationsList = () => import('@components/notifications/NotificationsContainer')

export default {
  name: 'NavbarNotifications',
  components: {
    ActivityList,
    NotificationsList
  },

  data () {
    return {
      active: 'notifications'
    }
  }
}
</script>
